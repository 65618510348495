import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Menu from '../menu/Menu.js';
import { FaSpinner } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../index.css';
import axiosInstance from '../services/axiosInstance';
import useAuth from '../services/useAuth';


function ListFornecedores() {
    useAuth();

    const [fornecedores, setFornecedores] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState(location.state && location.state.successMessage);

    useEffect(() => {
        axiosInstance.get("fornecedores/")
            .then(result => {
                setFornecedores(result.data);
                setIsLoading(false); 
            })
            .catch(error => {
                console.error('Erro ao buscar fornecedores:', error);
                setErrorMessage('Erro ao carregar fornecedores. Por favor, tente novamente mais tarde.');
                setIsLoading(false);
            });

        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage(null);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    const filteredFornecedores = fornecedores.filter(fornecedor => {
        return (
            (fornecedor.nome && fornecedor.nome.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (fornecedor.cnpj && fornecedor.cnpj.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (fornecedor.nomeFantasia && fornecedor.nomeFantasia.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (fornecedor.razaoSocial && fornecedor.razaoSocial.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (fornecedor.pessoaContato && fornecedor.pessoaContato.toLowerCase().includes(searchTerm.toLowerCase()))
        ) &&
            (filterType === '' || fornecedor.tipoPessoa === filterType);
    });

    const handleFilterChange = (event) => {
        setFilterType(event.target.value);
    };

    const deletarFornecedor = (id) => {
        const isConfirmed = window.confirm('Tem certeza que deseja excluir este fornecedor?');

        if (isConfirmed) {
            axiosInstance.delete(`fornecedores/${id}`)
                .then(() => {
                    setFornecedores(fornecedores.filter(fornecedor => fornecedor.id !== id));
                    navigate('/fornecedores', { state: { successMessage: 'Fornecedor deletado com sucesso!' } });
                })
                .catch(error => {
                    console.error('Erro ao deletar fornecedor:', error);
                });

        } else {
            console.log('Exclusão cancelada!')
        }
    };

    const editarFornecedor = (id) => {
        navigate(`/fornecedor/editar/${id}`);
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <Menu />
            <div className="layout-container">
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="py-3 mb-4">Lista de Fornecedores</h4>
                        {successMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                {successMessage}
                            </div>
                        )}
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Pesquisar"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            Filtrar:
                            <select className="form-select mb-2" value={filterType} onChange={handleFilterChange}>
                                <option value="">Todos os tipos</option>
                                <option value="juridica">Pessoa Juridica</option>
                                <option value="fisica">Pessoa Fisica</option>
                            </select>
                        </div>
                        <Link to={"/fornecedor/cadastro"}>
                            <button className='btn btn-success'>Adicionar Fornecedor</button>
                        </Link>
                        <hr />
                        {errorMessage && (
                            <div className="alert alert-danger mt-3" role="alert">
                                {errorMessage}
                            </div>
                        )}
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <FaSpinner className="mr-2" size={20} color="#007bff" /> Carregando...
                            </div>
                        ) : (
                            fornecedores.length === 0 ? (
                                <div>Não há fornecedores adicionados</div>
                            ) : (
                                <div>
                                    {filteredFornecedores.length === 0 ? (
                                        <div>Não há itens para o filtro selecionado.</div>
                                    ) : (
                                        <div className="row">
                                            {filteredFornecedores.map(fornecedor => (
                                                <div key={fornecedor.id} className="col-md-4 mb-4">
                                                    <div className="card" style={{ position: 'relative', minHeight: '280px' }}>
                                                        <div className="card-body">
                                                            <h5>{fornecedor.tipoPessoa === 'juridica' ? fornecedor.razaoSocial : fornecedor.nome}</h5>
                                                            <p>CNPJ/CPF: {fornecedor.tipoPessoa === 'juridica' ? fornecedor.cnpj : fornecedor.cpf}</p>
                                                            <p>Telefone: {fornecedor.telefone}</p>
                                                            {fornecedor.tipoPessoa === 'juridica' &&
                                                                <p>Pessoa para Contato: {fornecedor.pessoaContato}</p>
                                                            }
                                                        </div>
                                                        <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                                                            <button className="btn btn-bege me-1" onClick={() => editarFornecedor(fornecedor.id)}>
                                                                Editar
                                                            </button>
                                                            <button className="btn btn-danger me-1" onClick={() => deletarFornecedor(fornecedor.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListFornecedores;
