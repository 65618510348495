import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi'; // Importando os ícones do React Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import { FaUtensilSpoon, FaShoppingCart, FaBookOpen } from 'react-icons/fa';
import { FaDollarSign } from 'react-icons/fa';
import useAuth from '../services/useAuth';

function Menu() {

    useAuth();
    
    const [menuOpen, setMenuOpen] = useState(true);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <aside id="layout-menu"  className={`layout-menu menu-vertical menu bg-menu-theme ${menuOpen ? 'open' : ''}`}>
            
            <div className="app-brand demo">
                <a href="/home" className="app-brand-link">
                    <span style={{ marginLeft: '40px' }} className="app-brand-logo demo">
                        <span>
                            <img style={{ width: '100px', height: '50px' }} src={`${process.env.PUBLIC_URL}/assets/img/favicon/doceriasmartimg.svg`} alt="Logo" />
                        </span>
                    </span>
                </a>
                <button className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none" onClick={toggleMenu}>
                    {menuOpen ? <BiChevronRight /> : <BiChevronLeft />} {/* Renderizando o ícone de acordo com o estado do menu */}
                </button>
            </div>
            <div className="menu-inner-shadow"></div>
            <ul style={{marginTop: '1rem'}} className="menu-inner py-1">
                <li className="menu-item">
                    <Link to={"/home"}>
                        <a href="javascript:void(0);" className="menu-link">
                            <i className="menu-icon tf-icons bx bx-home-circle"></i>
                            <div data-i18n="Form Layouts">Inicio</div>
                        </a>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to={"/listClient"}>
                        <a href="javascript:void(0);" className="menu-link">
                            <i className="menu-icon tf-icons bx bx-user"></i>
                            <div data-i18n="Form Layouts">Clientes</div>
                        </a>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to={"/estoque"}>
                        <a href="#" className="menu-link">
                            <i className="menu-icon tf-icons bx bx-table"></i>
                            <div data-i18n="Tables">Matéria Prima</div>
                        </a>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to={"/fornecedores"}>
                        <a href="#" target="_blank" className="menu-link">
                            <i className="menu-icon tf-icons bx bx-box"></i>
                            <div data-i18n="Datatables">Fornecedor</div>
                        </a>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to={"/receita/listar"}>
                        <a href="#" className="menu-link">
                        <FaUtensilSpoon style={{width: '16px', height: '18px', }} className="menu-icon tf-icons bx bx"/>
                            <div style={{marginLeft: '0.5rem'}} data-i18n="Tables">Receitas</div>
                        </a>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to={"/custosfixos"}>
                        <a href="#" className="menu-link">
                            <FaShoppingCart style={{width: '16px', height: '18px'}} className="menu-icon tf-icons bx"/>
                            <div style={{marginLeft: '0.5rem'}} data-i18n="Tables">Produtos</div>
                        </a>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to={"/custosfixos"}>
                        <a href="#" className="menu-link">
                            <i className="menu-icon tf-icons bx bx-list-check"></i>
                            <div data-i18n="Tables">Custos Fixos</div>
                        </a>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to={"/financeiro"}>
                        <a href="#" className="menu-link tf-icons bx">
                            <FaDollarSign  style={{width: '16px', height: '18px'}} />
                            <div style={{marginLeft: '1rem'}} data-i18n="Tables">Financeiro</div>
                        </a>
                    </Link>
                </li>
                <li className="menu-item">
                    <Link to={"/tutoriais   "}>
                        <a href="#" className="menu-link">
                            <FaBookOpen style={{width: '14px', height: '18px'}} className="menu-icon tf-icons bx"/>
                            <div style={{marginLeft: '0.5rem'}} data-i18n="Tables">Tutoriais</div>
                        </a>
                    </Link>
                </li>
            </ul>
            <div className="fixed-bottom p-3">
                <a href="https://wa.me/5584999052481" target="_blank" rel="noopener noreferrer" className="btn btn-success btn-lg rounded-circle shadow">
                 <FontAwesomeIcon icon={faWhatsapp} />
                </a>
            </div>
        </aside>


    );
}

export default Menu;
