import React, { useEffect, useState } from 'react';
import Menu from '../menu/Menu.js';
import axiosInstance from '../services/axiosInstance'; // Importando o axiosInstance configurado
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBirthdayCake, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../services/useAuth';
import '../../index.css'; // Ensure the CSS file with the styles is imported

function Home() {
    useAuth();

    const [greeting, setGreeting] = useState('');
    const [birthdayClients, setBirthdayClients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const date = new Date();
        const hour = date.getHours();

        if (hour >= 5 && hour < 12) {
            setGreeting('Bom dia!');
        } else if (hour >= 12 && hour < 18) {
            setGreeting('Boa tarde!');
        } else {
            setGreeting('Boa noite!');
        }

        // Buscar clientes com aniversário no mês atual
        axiosInstance.get('/cliente/')
            .then(result => {
                const currentMonth = date.getMonth() + 1; // Janeiro é 0!
                const birthdayClients = result.data.filter(client => {
                    if (client.dataAniversario) {
                        const [clientYear, clientMonth] = client.dataAniversario.split('-');
                        return parseInt(clientMonth) === currentMonth;
                    }
                    return false;
                });
                setBirthdayClients(birthdayClients);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Erro ao buscar clientes:', error);
                setIsLoading(false);
            });
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleTouchMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleLogout = () => {
        // Limpar o token de acesso do localStorage
        localStorage.removeItem('token');
        
        // Redirecionar para a página de login
        window.location.href = '/'; // Substitua '/login' pelo caminho para a página de login do seu aplicativo
    };

    return (
        <div className={`layout-wrapper layout-content-navbar ${isMenuOpen ? 'menu-open' : ''}`}>
            <div className="layout-container">
                <Menu />
                
                <div className="container-xxl flex-grow-1 container-p-y" style={{ width: '100%', overflowX: 'auto', touchAction: 'pan-x' }}>
                    <div className="mobile-menu" onClick={toggleMenu} onTouchStart={handleTouchMenu}>
                        <div className="line1"></div>
                        <div className="line2"></div>
                        <div className="line3"></div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h3 className="py-3 mb-0">{greeting}</h3>
                        <button className="logout-button" onClick={handleLogout}>
                            <FontAwesomeIcon icon={faSignOutAlt} className="icon" />
                            Logout
                        </button>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div style={{ width: '40%', minWidth: '300px' }} className="card mb-4">
                                <div className="card-body">
                                    <h4 className="card-title">
                                        <FontAwesomeIcon icon={faBirthdayCake} className="mr-2" />
                                        {' '}
                                        Aniversariantes do Mês
                                    </h4>
                                    {isLoading ? (
                                        <div>Carregando...</div>
                                    ) : (
                                        <div>
                                            {birthdayClients.length > 0 ? (
                                                <ul>
                                                    {birthdayClients
                                                        .sort((a, b) => {
                                                            const dateA = new Date(a.dataAniversario);
                                                            const dateB = new Date(b.dataAniversario);

                                                            const dayMonthA = dateA.getMonth() * 100 + dateA.getDate();
                                                            const dayMonthB = dateB.getMonth() * 100 + dateB.getDate();
                                                            return dayMonthA - dayMonthB;
                                                        })
                                                        .map(client => (
                                                            <li key={client.id}>{client.nome} - {formatDate(client.dataAniversario)}</li>
                                                        ))}
                                                </ul>
                                            ) : (
                                                <p>Não há clientes aniversariantes neste mês.</p>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
            
        </div>
    );
}

export default Home;

function formatDate(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}`;
}
