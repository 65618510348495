import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from '../services/axiosInstance'; // Ajuste o caminho conforme necessário
import InputMask from 'react-input-mask';
import Menu from '../menu/Menu.js';
import '../../index.css';
import { useNavigate } from 'react-router-dom';
import useAuth from '../services/useAuth'; // Ajuste o caminho conforme necessário
import axios from 'axios';

function CreateClient() {

  useAuth();

  const [cliente, setCliente] = useState({
    nome: '',
    numeroWhatsapp: '',
    diaAniversario: '',
    mesAniversario: '',
    cpf: '',
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    instagram: '',
    email: '',
    informacoesAdicionais: ''
  });

  const [clientes, setClientes] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const formRef = useRef(null); // Referência para o formulário

  const meses = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' }
  ];

  useEffect(() => {
    axiosInstance.get("/cliente/").then(result => {
      setClientes(result.data);
    });

    const scripts = [
      '../assets/vendor/libs/jquery/jquery.js',
      '../assets/vendor/libs/popper/popper.js',
      '../assets/vendor/js/bootstrap.js',
      '../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js',
      '../assets/js/main.js'
    ];

    const loadScripts = () => {
      scripts.forEach(src => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        document.head.appendChild(script);
      });
    };

    if (window.Menu) {
      loadScripts();
    } else {
      window.addEventListener('load', loadScripts);
    }

    return () => {
      scripts.forEach(src => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script) {
          document.head.removeChild(script);
        }
      });
    };
  }, []);

  const validateForm = () => {
    const newErrors = {};

    if (!cliente.nome.trim()) {
      newErrors.nome = 'O campo nome é obrigatório';
    }

    if (cliente.diaAniversario && cliente.mesAniversario) {
      const maxDay = getMaxDay(cliente.mesAniversario);
      if (parseInt(cliente.diaAniversario, 10) > maxDay) {
        newErrors.diaAniversario = `O mês selecionado tem no máximo ${maxDay} dias.`;
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      // Rolar para o topo do formulário em caso de erro
      formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    return Object.keys(newErrors).length === 0;
  };

  function handleChange(event) {
    const { name, value } = event.target;

    setCliente(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'cep' && value.length === 9) {
      axios.get(`https://viacep.com.br/ws/${value.replace('-', '')}/json/`).then(response => {
        const { logradouro, bairro, localidade, uf } = response.data;
        setCliente(prevState => ({
          ...prevState,
          endereco: `${logradouro}, ${bairro}, ${localidade} - ${uf}`
        }));
      }).catch(error => {
        console.error('Erro ao consultar CEP:', error);
      });
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = validateForm();

    if (isValid) {
      let dataAniversario = '';

      if (cliente.diaAniversario && cliente.mesAniversario) {
        dataAniversario = `2024-${cliente.mesAniversario.padStart(2, '0')}-${cliente.diaAniversario.padStart(2, '0')}`;
      } else if (cliente.diaAniversario) {
        dataAniversario = `2024-01-${cliente.diaAniversario.padStart(2, '0')}`;
      } else if (cliente.mesAniversario) {
        dataAniversario = `2024-${cliente.mesAniversario.padStart(2, '0')}-01`;
      }

      const clienteData = {
        ...cliente,
        dataAniversario,
      };

      axiosInstance.post("/cliente/", clienteData)
        .then(result => {
          console.log(result);
          setShowSuccessMessage(true);
          setCliente({
            nome: '',
            numeroWhatsapp: '',
            diaAniversario: '',
            mesAniversario: '',
            cpf: '',
            cep: '',
            endereco: '',
            numero: '',
            complemento: '',
            instagram: '',
            email: '',
            informacoesAdicionais: ''
          });
        })
        .catch(error => {
          console.error('Erro ao cadastrar cliente:', error);
        });

      navigate('/listClient', { state: { successMessage: 'Cliente cadastrado com sucesso!' } });
    }
  };

  const getMaxDay = (month) => {
    switch (month) {
      case '02':
        return 29; // Considerando anos bissextos
      case '04':
      case '06':
      case '09':
      case '11':
        return 30;
      default:
        return 31;
    }
  };

  const maxDay = getMaxDay(cliente.mesAniversario);

  return (
    <div className="layout-wrapper layout-content-navbar">
      <Menu />
      <div className="layout-container">
        <div className="content-wrapper">
          <div className="container-xxl flex-grow-1 container-p-y">
            <h4 className="py-3 mb-4"><span className="text-muted fw-light">Cadastro/</span> Clientes</h4>
            {showSuccessMessage && (
              <div className="alert alert-success mt-3" role="alert">
                Cadastro realizado com sucesso!
              </div>
            )}
            <div className="row">
              <div className="col-xl">
                <div className="card mb-4">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Adicionar Cliente</h5>
                  </div>
                  <div className="card-body">
                    <form ref={formRef} onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label className="form-label required" htmlFor="basic-default-fullname">Nome </label>
                        <input type="text" className="form-control" id="basic-default-fullname" name="nome" value={cliente.nome} onChange={handleChange} placeholder="ex.: Ana Oliveira Silva" />
                        {errors.nome && <div className="text-danger">{errors.nome}</div>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="basic-default">Número de WhatsApp</label>
                        <InputMask
                          mask="(99) 99999-9999"
                          className="form-control"
                          placeholder="ex.: (12) 99999-9999"
                          id="basic-default"
                          name="numeroWhatsapp"
                          value={cliente.numeroWhatsapp}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="basic-default-day">Dia de Aniversário</label>
                        <input
                          type="number"
                          className="form-control"
                          id="basic-default-day"
                          name="diaAniversario"
                          value={cliente.diaAniversario}
                          onChange={handleChange}
                          placeholder="Dia"
                          min="1"
                          max={maxDay}
                        />
                        {errors.diaAniversario && <div className="text-danger">{errors.diaAniversario}</div>}
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="basic-default-month">Mês de Aniversário</label>
                        <select
                          className="form-control"
                          id="basic-default-month"
                          name="mesAniversario"
                          value={cliente.mesAniversario}
                          onChange={handleChange}
                        >
                          <option value="">Selecione o mês</option>
                          {meses.map(mes => (
                            <option key={mes.value} value={mes.value}>{mes.label}</option>
                          ))}
                        </select>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="basic-default">CPF</label>
                        <InputMask
                          mask="999.999.999-99"
                          className="form-control"
                          placeholder="ex.: 999.999.999-99"
                          id="basic-default"
                          name="cpf"
                          value={cliente.cpf}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="basic-default">CEP </label>
                        <InputMask
                          mask="99999-999"
                          className="form-control"
                          placeholder="ex.: 99999-999"
                          id="basic-default"
                          name="cep"
                          value={cliente.cep}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="basic-default">Endereço</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="ex.: Av. dos Bandeirantes 3430 - São Paulo/SP"
                          id="basic-default"
                          name="endereco"
                          value={cliente.endereco}
                          onChange={handleChange}
                          readOnly
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="basic-default">Número</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="ex.: 12"
                          id="basic-default"
                          name="numero"
                          value={cliente.numero}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="basic-default">Complemento</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="ex.: Bloco B Ap.12"
                          id="basic-default"
                          name="complemento"
                          value={cliente.complemento}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="basic-default">Instagram</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="ex.: @doceconfeitaria"
                          id="basic-default"
                          name="instagram"
                          value={cliente.instagram}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="basic-default-email">Email</label>
                        <div className="input-group input-group-merge">
                          <input
                            type="text"
                            id="basic-default-email"
                            className="form-control"
                            placeholder="ex.: ana.oliveira@gmail.com"
                            aria-label="john.doe"
                            aria-describedby="basic-default-email2"
                            name="email"
                            value={cliente.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label" htmlFor="basic-default-message">Informações Adicionais</label>
                        <textarea
                          id="basic-default-message"
                          className="form-control"
                          placeholder="Adicione alguma informação que desejar"
                          name="informacoesAdicionais"
                          value={cliente.informacoesAdicionais}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      
                      <button style={{fontSize: '15px'}} type="submit" className="btn-green">Enviar</button>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateClient;
