import React, { useEffect, useState } from 'react';
import axiosInstance from '../services/axiosInstance';
import Menu from '../menu/Menu';
import { FaSpinner } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../services/useAuth';

function Estoque() {
    useAuth();

    const [materiasPrimas, setMateriasPrimas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('');
    const navigate = useNavigate();

    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState(location.state && location.state.successMessage);

    useEffect(() => {
        fetchMateriasPrimas();
        
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage(null);
            }, 5000);

            return () => clearTimeout(timer);
        }

        if (showDeleteMessage) {
            const timer = setTimeout(() => {
                setShowDeleteMessage(null);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [location, successMessage, showDeleteMessage]);

    const fetchMateriasPrimas = () => {
        setIsLoading(true);
        axiosInstance.get("/materiaprima/")
            .then(result => {
                if (Array.isArray(result.data)) {
                    setMateriasPrimas(result.data);
                } else {
                    setErrorMessage('Erro ao carregar matérias-primas. Resposta inesperada do servidor.');
                }
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Erro ao buscar Matéria Prima:', error);
                setErrorMessage('Erro ao carregar matérias-primas. Por favor, tente novamente mais tarde.');
                setIsLoading(false);
            });
    };

    const filteredMateriasPrimas = Array.isArray(materiasPrimas) ? materiasPrimas.filter(materiaPrima => {
        return (
            (materiaPrima.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
                materiaPrima.marca.toLowerCase().includes(searchTerm.toLowerCase()) ||
                materiaPrima.categoria.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (filterType === '' || materiaPrima.tipo === filterType)
        );
    }) : [];

    const handleFilterChange = (event) => {
        setFilterType(event.target.value);
    };

    const editarMateriaPrima = (id) => {
        navigate(`/materiaPrima/editar/${id}`);
    };

    const deletarMateriaPrima = (id) => {
        const isConfirmed = window.confirm('Tem certeza que deseja excluir esta matéria-prima?');

        if (isConfirmed) {
            axiosInstance.delete(`/materiaprima/${id}`)
                .then(() => {
                    setMateriasPrimas(materiasPrimas.filter(materiaPrima => materiaPrima.id !== id));
                    setShowDeleteMessage(true);
                })
                .catch(error => {
                    console.error('Erro ao deletar matéria-prima:', error);
                });
        } else {
            console.log('Exclusão cancelada');
        }
    };

    const sortByNome = () => {
        const sortedMateriasPrimas = [...materiasPrimas].sort((a, b) => {
            return a.nome.localeCompare(b.nome); // Ordena por nome
        });
        setMateriasPrimas(sortedMateriasPrimas);
    };

    const sortByValorUnitario = () => {
        const sortedMateriasPrimas = [...materiasPrimas].sort((a, b) => {
            return a.valorUnitario - b.valorUnitario; // Ordena por preço (valorUnitario)
        });
        setMateriasPrimas(sortedMateriasPrimas);
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <Menu />
            <div className="layout-container">
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="py-3 mb-4">Matéria Prima</h4>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Pesquisar"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            Filtrar:
                            <select className="form-select mb-2" value={filterType} onChange={handleFilterChange}>
                                <option value="">Todos os tipos</option>
                                <option value="Ingrediente">Ingrediente</option>
                                <option value="Embalagem">Embalagem</option>
                                <option value="Decoracao">Decoração</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <button className="btn btn-outline-primary me-2" onClick={sortByNome}>
                                Ordenar por Nome
                            </button>
                            <button className="btn btn-outline-primary" onClick={sortByValorUnitario}>
                                Ordenar por Preço
                            </button>
                        </div>
                        {successMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                {successMessage}
                            </div>
                        )}
                        <Link to={"/createMateriaPrima"}>
                            <button className='btn btn-success'>Adicionar Matéria Prima</button>
                        </Link>
                        {errorMessage && (
                            <div className="alert alert-danger mt-3" role="alert">
                                {errorMessage}
                            </div>
                        )}
                        {showDeleteMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                Matéria-prima removida com sucesso!
                            </div>
                        )}
                        <hr />
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <FaSpinner className="mr-2" size={20} color="#007bff" /> Carregando...
                            </div>
                        ) : 
                            materiasPrimas.length === 0 ? (
                                <div>Não há matérias primas cadastradas.</div>
                            ) : filteredMateriasPrimas.length === 0 ? (
                                <div>Não há itens para o filtro selecionado.</div>
                            ) : (
                                <div className="row">
                                    {filteredMateriasPrimas.map(materiaPrima => (
                                        <div key={materiaPrima.id} className="col-md-4 mb-4">
                                            <div className="card" style={{ position: 'relative', minHeight: '250px' }}>
                                                <div className="card-body">
                                                    <h5 className="card-title">{materiaPrima.nome} - {materiaPrima.quantidadePorEmbalagem} {materiaPrima.unidadeMateriaPrima}</h5>
                                                    <hr />
                                                    <p className="card-subtitle text-muted mb-3">Marca: {materiaPrima.marca}</p>
                                                    <p className="card-subtitle text-muted mb-3">Categoria: {materiaPrima.categoria}</p>
                                                    <p className="card-subtitle text-muted mb-3">Tipo: {materiaPrima.tipo}</p>
                                                    <p className="card-subtitle text-muted mb-3">Custo por Unidade(R$): {materiaPrima.valorUnitario != null ? materiaPrima.valorUnitario.toFixed(2).replace('.', ',') : 'Valor não fornecido'}</p>
                                                    <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                                                        <button className="btn btn-bege me-1" onClick={() => editarMateriaPrima(materiaPrima.id)}>
                                                            Editar
                                                        </button>
                                                        <button className="btn btn-danger" onClick={() => deletarMateriaPrima(materiaPrima.id)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Estoque;
