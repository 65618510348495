import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance'; // Importando o axiosInstance configurado
import Menu from '../menu/Menu.js';
import InputMask from 'react-input-mask';
import '../../index.css';
import axios from 'axios';
import useAuth from '../services/useAuth';

function EditClient() {
    useAuth();
    const { id } = useParams();
    const [cliente, setCliente] = useState({
        nome: '',
        numeroWhatsapp: '',
        diaAniversario: '',
        mesAniversario: '',
        cpf: '',
        cep: '',
        endereco: '',
        numero: '',
        complemento: '',
        instagram: '',
        email: '',
        informacoesAdicionais: ''
    });
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const navigate = useNavigate();

    const meses = [
        { nome: 'Janeiro', valor: '01' },
        { nome: 'Fevereiro', valor: '02' },
        { nome: 'Março', valor: '03' },
        { nome: 'Abril', valor: '04' },
        { nome: 'Maio', valor: '05' },
        { nome: 'Junho', valor: '06' },
        { nome: 'Julho', valor: '07' },
        { nome: 'Agosto', valor: '08' },
        { nome: 'Setembro', valor: '09' },
        { nome: 'Outubro', valor: '10' },
        { nome: 'Novembro', valor: '11' },
        { nome: 'Dezembro', valor: '12' }
    ];

    useEffect(() => {
        axiosInstance.get(`/cliente/${id}`)
            .then(result => {
                const { dataAniversario, ...rest } = result.data;
                let dia = '';
                let mes = '';
                if (dataAniversario) {
                    const [ano, mesAniv, diaAniv] = dataAniversario.split('-');
                    dia = diaAniv || '';
                    mes = mesAniv || '';
                }
                setCliente({
                    ...rest,
                    diaAniversario: dia,
                    mesAniversario: mes,
                    numero: result.data.numero || '', // Garantindo que o número não seja null
                });
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Erro ao buscar cliente:', error);
                setShowErrorMessage(true);
                setIsLoading(false);
            });
    }, [id]);

    function handleChange(event) {
        const { name, value } = event.target;
        setCliente(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    function handleCEPChange(event) {
        const cep = event.target.value.replace(/\D/g, '');
        setCliente(prevState => ({
            ...prevState,
            cep: cep,
        }));
        if (cep.length === 8) {
            setIsLoading(true);
            axios.get(`https://viacep.com.br/ws/${cep}/json/`)
                .then(response => {
                    const { logradouro, bairro, complemento, localidade, uf } = response.data;
                    setCliente(prevState => ({
                        ...prevState,
                        endereco: `${logradouro}, ${bairro}, ${localidade} - ${uf}`,
                        complemento: complemento,
                        cidade: localidade,
                        estado: uf,
                    }));
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Erro ao buscar CEP:', error);
                    setIsLoading(false);
                });
        } else {
            setCliente(prevState => ({
                ...prevState,
                endereco: '',
                complemento: '',
                cidade: '',
                estado: '',
            }));
        }
    }

    function validateForm() {
        let formErrors = {};
        if (!cliente.nome) formErrors.nome = "Nome é obrigatório";
        // Add more validation rules as needed
        setErrors(formErrors);

        if (Object.keys(formErrors).length > 0) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }

        return Object.keys(formErrors).length === 0;
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (!validateForm()) return;
    
        const diaAniversario = cliente.diaAniversario ? cliente.diaAniversario.padStart(2, '0') : '';
        const mesAniversario = cliente.mesAniversario ? cliente.mesAniversario.padStart(2, '0') : '';
        const dataAniversario = diaAniversario && mesAniversario ? `2024-${mesAniversario}-${diaAniversario}` : '';
    
        const { diaAniversario: dia, mesAniversario: mes, ...rest } = cliente;
        const clienteData = {
            ...rest,
            dataAniversario: dataAniversario,
        };
    
        // Log the JSON to the console
        
        
        axiosInstance.put(`/cliente/`, clienteData)
            .then(result => {
                console.log(result);
                setShowSuccessMessage(true);
                navigate('/listClient', { state: { successMessage: 'Cliente atualizado com sucesso!' } });
            })
            .catch(error => {
                console.error('Erro ao atualizar cliente:', error);
                setShowErrorMessage(true);
            });
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <Menu />
            <div className="layout-container">
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="py-3 mb-4">Editar Cliente</h4>
                        {isLoading ? (
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Carregando...</span>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-xl">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <form onSubmit={handleSubmit}>
                                                <div className="mb-3">
                                                    <label className="form-label required" htmlFor="basic-default-fullname">Nome </label>
                                                    <input type="text" className="form-control" id="basic-default-fullname" name="nome" value={cliente.nome} onChange={handleChange} placeholder="ex.: Ana Oliveira Silva" />
                                                    {errors.nome && <div className="text-danger">{errors.nome}</div>}
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="basic-default">Número de WhatsApp</label>
                                                    <InputMask
                                                        mask="(99) 99999-9999"
                                                        className="form-control"
                                                        placeholder="ex.: (12) 99999-9999"
                                                        id="basic-default"
                                                        name="numeroWhatsapp"
                                                        value={cliente.numeroWhatsapp}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="basic-default-day">Dia de Aniversário</label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        id="basic-default-day"
                                                        name="diaAniversario"
                                                        value={cliente.diaAniversario}
                                                        onChange={handleChange}
                                                        placeholder="Dia"
                                                        min="1"
                                                        max="31"
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="basic-default-month">Mês de Aniversário</label>
                                                    <select
                                                        className="form-control"
                                                        id="basic-default-month"
                                                        name="mesAniversario"
                                                        value={cliente.mesAniversario}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Selecione o mês</option>
                                                        {meses.map(mes => (
                                                            <option key={mes.valor} value={mes.valor}>
                                                                {mes.nome}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="basic-default">CPF</label>
                                                    <InputMask
                                                        mask="999.999.999-99"
                                                        className="form-control"
                                                        placeholder="ex.: 999.999.999-99"
                                                        id="basic-default"
                                                        name="cpf"
                                                        value={cliente.cpf}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="basic-default">CEP</label>
                                                    <InputMask
                                                        mask="99999-999"
                                                        className="form-control"
                                                        placeholder="ex.: 12345-678"
                                                        id="basic-default"
                                                        name="cep"
                                                        value={cliente.cep}
                                                        onChange={handleCEPChange}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="basic-default">Endereço</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="ex.: Rua, Avenida..."
                                                        id="basic-default"
                                                        name="endereco"
                                                        value={cliente.endereco}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="basic-default">Número</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="ex.: 12"
                                                        id="basic-default"
                                                        name="numero"
                                                        value={cliente.numero}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="basic-default">Complemento</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="ex.: Bloco B Ap.12"
                                                        id="basic-default"
                                                        name="complemento"
                                                        value={cliente.complemento}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="basic-default">Instagram</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="ex.: @doceconfeitaria"
                                                        id="basic-default"
                                                        name="instagram"
                                                        value={cliente.instagram}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="basic-default-email">Email</label>
                                                    <div className="input-group input-group-merge">
                                                        <input
                                                            type="text"
                                                            id="basic-default-email"
                                                            className="form-control"
                                                            placeholder="ex.: ana.oliveira@gmail.com"
                                                            aria-label="john.doe"
                                                            aria-describedby="basic-default-email2"
                                                            name="email"
                                                            value={cliente.email}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="basic-default-message">Informações Adicionais</label>
                                                    <textarea
                                                        id="basic-default-message"
                                                        className="form-control"
                                                        placeholder="Adicione alguma informação que desejar"
                                                        name="informacoesAdicionais"
                                                        value={cliente.informacoesAdicionais}
                                                        onChange={handleChange}
                                                    ></textarea>
                                                </div>
                                                <button style={{fontSize: '15px'}} type="submit" className="btn-green">Enviar</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {showSuccessMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                Cliente atualizado com sucesso!
                            </div>
                        )}
                        {showErrorMessage && (
                            <div className="alert alert-danger mt-3" role="alert">
                                Erro ao atualizar cliente. Por favor, tente novamente.
                            </div>
                        )}
                    </div>
                    <footer className="content-footer footer bg-footer-theme">
                        <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                            <div className="mb-2 mb-md-0">
                                ©{new Date().getFullYear()} by <a href="https://themeselection.com" target="_blank" className="footer-link fw-medium">Doceria Smart</a>
                            </div>
                        </div>
                    </footer>
                    <div className="content-backdrop fade"></div>
                </div>
            </div>
        </div>
    );
}

export default EditClient;
