import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login() {
    const navigate = useNavigate();

    const [loginData, setLoginData] = useState({
        email: '',
        senha: ''
    });
    const [error, setError] = useState('');

    function handleChange(event) {
        const { name, value } = event.target;
        setLoginData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            const response = await axios.post("https://doceriasmart-352c4397370b.herokuapp.com/login", loginData);
            const { token } = response.data;
            localStorage.setItem('token', token);
            navigate("/home");
        } catch (error) {
            console.error('Login error:', error);
            setError('Usuário ou senha incorretos');
        }
    }
    

    return (
        <div className="container-fluid vh-100 d-flex align-items-center justify-content-center bg-gradient">
            <div className="card p-4 shadow-lg rounded-3">
            <span style={{ margin: '0 auto' }} className="app-brand-logo demo">
                        <span>
                            <img style={{ width: '100px', height: '50px' }} src={`${process.env.PUBLIC_URL}/assets/img/favicon/doceriasmartimg.svg`} alt="Logo" />
                        </span>
                    </span>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={loginData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="senha" className="form-label">Senha</label>
                        <input
                            type="password"
                            className="form-control"
                            id="senha"
                            name="senha"
                            value={loginData.senha}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="d-grid gap-2">
                        <button type="submit" className="btn-green">Entrar</button>
                    </div>
                    {error && <p className="text-danger mt-3">{error}</p>}
                </form>
            </div>
        </div>
    );
}

export default Login;
