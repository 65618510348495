import React from 'react';
import Menu from '../menu/Menu';
import ReactPlayer from 'react-player';

const VideoPlayer = () => {
    return (
        <div className="layout-wrapper layout-content-navbar">
            <Menu />
            <div className="layout-container">
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="py-3 mb-4">Video Teste</h4>
                        <div>
                            <ReactPlayer
                                url={`${process.env.PUBLIC_URL}/assets/videos/videoteste.mp4`}
                                width="240px"
                                height="424px"
                                controls={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VideoPlayer;