import React, { useEffect, useState } from 'react';
import axiosInstance from '../services/axiosInstance'; // Importando axiosInstance
import Menu from '../menu/Menu';
import { FaSpinner } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../services/useAuth';

function ListClient() {
    
    useAuth();

    const [clientes, setClientes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filterType, setFilterType] = useState('');
    const navigate = useNavigate();
    const [ordem, setOrdem] = useState('asc');
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState(location.state && location.state.successMessage);

    useEffect(() => {
        axiosInstance.get("/cliente/")
            .then(result => {
                setClientes(result.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Erro ao buscar clientes:', error);
                setErrorMessage('Erro ao carregar clientes. Por favor, tente novamente mais tarde.');
                setIsLoading(false);
            });

        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage(null);
            }, 5000);
            return () => clearTimeout(timer);
        }

        if (showDeleteMessage) {
            const timer = setTimeout(() => {
                setShowDeleteMessage(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [successMessage, showDeleteMessage]);

    function formatDate(dateString) {
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}`;
    }

    const filtrarClientes = (cliente) => {
        return (
            cliente.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            formatDate(cliente.dataAniversario).includes(searchTerm.toLowerCase()) ||
            cliente.endereco.toLowerCase().includes(searchTerm.toLowerCase()) ||
            cliente.instagram.toLowerCase().includes(searchTerm.toLowerCase()) ||
            cliente.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    const ordenarClientes = () => {
        const sortedClientes = [...clientes].sort((a, b) => {
            if (ordem === 'asc') {
                return a.nome.localeCompare(b.nome);
            } else {
                return b.nome.localeCompare(a.nome);
            }
        });
        setClientes(sortedClientes);
        setOrdem(ordem === 'asc' ? 'desc' : 'asc');
    };

    const handleFilterChange = (event) => {
        setFilterType(event.target.value);
    };

    const editarCliente = (id) => {
        navigate(`/cliente/editar/${id}`);
    };

    const deletarCliente = (id) => {
        const isConfirmed = window.confirm('Tem certeza que deseja excluir este cliente?');
        if (isConfirmed) {
            axiosInstance.delete(`/cliente/${id}`)
                .then(() => {
                    setClientes(clientes.filter(cliente => cliente.id !== id));
                    setShowDeleteMessage(true);
                })
                .catch(error => {
                    console.error('Erro ao deletar cliente:', error);
                    setErrorMessage('Erro ao deletar cliente. Por favor, tente novamente mais tarde.');
                });
        } else {
            console.log('Exclusão cancelada');
        }
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <Menu />
            <div className="layout-container">
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="py-3 mb-4">Clientes</h4>
                        {successMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                {successMessage}
                            </div>
                        )}
                        <div className="mb-3">
                            <div className="mb-3 input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Pesquisar"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        <Link to={"/createClient"}>
                            <button className='btn btn-success'>Adicionar Cliente</button>
                        </Link>
                        <button style={{ marginLeft: '10px' }} className='btn btn-outline-primary' onClick={ordenarClientes}>Ordenar por nome</button>
                        {errorMessage && (
                            <div className="alert alert-danger mt-3" role="alert">
                                {errorMessage}
                            </div>
                        )}
                        {showDeleteMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                Cliente removido com sucesso!
                            </div>
                        )}
                        <hr />
                        {isLoading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <FaSpinner className="mr-2" size={20} color="#007bff" /> Carregando...
                            </div>
                        ) : (
                            <div>
                                {clientes.filter(filtrarClientes).length === 0 ? (
                                    <div className="alert alert-info mt-3" role="alert">
                                        Nenhum cliente encontrado.
                                    </div>
                                ) : (
                                    <div className="row">
                                        {clientes.filter(filtrarClientes).map(cliente => (
                                            <div key={cliente.id} className="col-md-4 mb-4">
                                                <div className="card" style={{ position: 'relative', minHeight: '270px' }}>
                                                    <div className="card-body">
                                                        <h5 className="card-title">{cliente.nome}</h5>
                                                        <p className="card-subtitle text-muted mb-3">WhatsApp: {cliente.numeroWhatsapp ? cliente.numeroWhatsapp : 'não fornecido'}</p>
                                                        <p className="card-subtitle text-muted mb-3">Data de Aniversário: {cliente.dataAniversario ? formatDate(cliente.dataAniversario) : 'não fornecida'}</p>
                                                        <p className="card-subtitle text-muted mb-3">Endereço: {cliente.endereco}{cliente.numero && `, ${cliente.numero}`}{cliente.complemento && ` - ${cliente.complemento}`}</p>
                                                        <p className="card-subtitle text-muted mb-3">Instagram: {cliente.instagram ? cliente.instagram : 'não fornecido'}</p>
                                                        <p className="card-subtitle text-muted mb-3">Email: {cliente.email ? cliente.email : 'não fornecido'}</p>
                                                        <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                                                            <button className="btn btn-bege me-1" onClick={() => editarCliente(cliente.id)}>
                                                                Editar
                                                            </button>
                                                            <button className="btn btn-danger" onClick={() => deletarCliente(cliente.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3" viewBox="0 0 16 16">
                                                                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListClient;
