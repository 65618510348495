import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CreateClient from './pages/cliente/createclient';
import ListClient from './pages/cliente/listclient';
import CreateMateriaPrima from './pages/materiaprima/createmateriaprima';
import CustosFixos from './pages/custofixo/CustoFixo';
import Home from './pages/home/Home';
import Estoque from './pages/estoque/Estoque';
import CreateFornecedor from './pages/fornecedor/createfornecedor';
import ListFornecedores from './pages/fornecedor/listfornecedor';
import Login from './pages/login/login';
import EditFornecedor from './pages/fornecedor/editfornecedor';
import EditClient from './pages/cliente/editclient';
import EditMateriaPrima from './pages/materiaprima/editmateriaprima';
import CreateReceita from './pages/receita/createreceita';
import VideoPlayer from './pages/tutoriais';
import ListaReceitas from './pages/receita/listreceita';
import LandingPage from './pages/landingpage/LandingPage';

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/createClient" element={<CreateClient />} />
                <Route path="/listClient" element={<ListClient />} />
                <Route path="/createMateriaPrima" element={<CreateMateriaPrima />} />
                <Route path="/custosfixos" element={<CustosFixos />} />
                <Route path="/estoque" element={<Estoque />} />
                <Route path="/home" element={<Home />} />
                <Route path="/" element={<LandingPage />} />
                <Route path="/fornecedor/cadastro" element={<CreateFornecedor />} />
                <Route path="/fornecedores" element={<ListFornecedores />} />
                <Route path="/fornecedor/editar/:id" element={<EditFornecedor />} />
                <Route path="/cliente/editar/:id" element={<EditClient />} />
                <Route path="/materiaprima/editar/:id" element={<EditMateriaPrima />} />
                <Route path="/receita/cadastro" element={<CreateReceita />} />
                <Route path="/receita/listar" element={<ListaReceitas />} />
                <Route path="/login" element={<Login />} />
                <Route path="/tutoriais" element={<VideoPlayer />} />
            </Routes>
        </Router>
    );
};

export default App;
