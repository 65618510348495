import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance'; // Ajuste o caminho conforme a localização de axiosInstance
import Menu from '../menu/Menu.js';
import InputMask from 'react-input-mask';
import '../../index.css';
import axios from 'axios';
import useAuth from '../services/useAuth';

function EditFornecedor() {

    useAuth();

    const { id } = useParams();
    const [fornecedor, setFornecedor] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [tipoPessoa, setTipoPessoa] = useState('')
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get(`fornecedores/${id}`) 
            .then(result => {
                setFornecedor(result.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Erro ao buscar fornecedor:', error);
            });
    }, [id]);

    function handleChange(event) {
        const { name, value } = event.target;
        setFornecedor(prevState => ({
            ...prevState,
            [name]: value
        }));

        const cnpjSemMascara = value.replace(/[^0-9]/g, '');

        if (name === 'cnpj' && cnpjSemMascara.length === 14) {
            axios.get(`https://api.cnpjs.dev/v1/${cnpjSemMascara}`).then(response => {
                const { razao_social, nome_fantasia } = response.data;
                setFornecedor(prevState => ({
                    ...prevState,
                    razaoSocial: razao_social,
                    nomeFantasia: nome_fantasia
                }));
            }).catch(error => {
                console.error('Erro ao consultar CNPJ:', error);
                window.alert('Erro ao consultar CNPJ')
                setFornecedor(prevState => ({
                    ...prevState,
                    razaoSocial: '',
                    nomeFantasia: ''
                }));
            });
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        axiosInstance.put(`fornecedores/`, fornecedor) // Aqui você usa axiosInstance com o caminho relativo
            .then(result => {
                console.log(result);
                setShowSuccessMessage(true);
            })
            .catch(error => {
                console.error('Erro ao atualizar fornecedor:', error);
            });

        navigate('/fornecedores', { state: { successMessage: 'Fornecedor atualizado com sucesso!' } });
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <Menu />
            <div className="layout-container">
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="py-3 mb-4">Editar Fornecedor</h4>
                        {isLoading ? (
                            <div>Carregando...</div>
                        ) : (
                            <div className="row">
                                <div className="col-xl">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <form onSubmit={handleSubmit}>

                                                {fornecedor.tipoPessoa === 'juridica' && (
                                                    <div>
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="basic-default-fullname">CNPJ</label>
                                                            <InputMask mask="99.999.999/9999-99" className="form-control" id="basic-default-fullname" name="cnpj" value={fornecedor.cnpj} onChange={handleChange} placeholder="ex.: 49.000.341/2231-23" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="basic-default-fullname">Razão Social</label>
                                                            <input type="text" className="form-control" id="basic-default-fullname" name="razaoSocial" value={fornecedor.razaoSocial} onChange={handleChange} placeholder="ex.: Doce Mania" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="basic-default-fullname">Nome Fantasia</label>
                                                            <input type="text" className="form-control" id="basic-default-fullname" name="nomeFantasia" value={fornecedor.nomeFantasia} onChange={handleChange} placeholder="ex.: Doce Mania LTDA" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="basic-default-fullname">IE: </label>
                                                            <input type="text" className="form-control" id="basic-default-fullname" name="ie" value={fornecedor.ie} onChange={handleChange} placeholder="ex.: 32903849392" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="basic-default-fullname">Telefone</label>
                                                            <input type="text" className="form-control" id="basic-default-fullname" name="telefone" value={fornecedor.telefone} onChange={handleChange} placeholder="ex.: 84 9993-3923" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="basic-default-fullname">Pessoa para Contato</label>
                                                            <input type="text" className="form-control" id="basic-default-fullname" name="pessoaContato" value={fornecedor.pessoaContato} onChange={handleChange} placeholder="ex.: Maria Silva" />
                                                        </div>
                                                    </div>
                                                )}
                                                {fornecedor.tipoPessoa === 'fisica' && (
                                                    <div>
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="basic-default-fullname">CPF</label>
                                                            <InputMask mask="999.999.999-99" className="form-control" id="basic-default-fullname" name="cpf" value={fornecedor.cpf} onChange={handleChange} placeholder="ex.: 123.456.789-00" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="basic-default-fullname">Nome</label>
                                                            <input type="text" className="form-control" id="basic-default-fullname" name="nome" value={fornecedor.nome} onChange={handleChange} placeholder="ex.: Maria Silva" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="basic-default-fullname">Telefone</label>
                                                            <input type="text" className="form-control" id="basic-default-fullname" name="telefone" value={fornecedor.telefone} onChange={handleChange} placeholder="ex.: 84 9993-3923" />
                                                        </div>
                                                    </div>
                                                )}

                                                <button style={{fontSize: '15px'}} type="submit" className="btn-green">Salvar</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {showSuccessMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                Fornecedor atualizado com sucesso!
                            </div>
                        )}
                    </div>
                    <footer className="content-footer footer bg-footer-theme">
                        <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                            <div className="mb-2 mb-md-0">
                                ©{new Date().getFullYear()} by <a href="https://themeselection.com" target="_blank" className="footer-link fw-medium">Doceria Smart</a>
                            </div>
                        </div>
                    </footer>
                    <div className="content-backdrop fade"></div>
                </div>
            </div>
        </div>
    );
}

export default EditFornecedor;
