import React, { useState, useEffect, useRef} from 'react';
import Menu from '../menu/Menu';
import axiosInstance from '../services/axiosInstance';
import { NumericFormat } from 'react-number-format';
import useAuth from '../services/useAuth';
import { useNavigate } from 'react-router-dom';

function CreateReceita() {
    useAuth();

    const navigate = useNavigate();
    
    const [nomeReceita, setNomeReceita] = useState('');
    const [ingredientes, setIngredientes] = useState([]);
    const [novoIngrediente, setNovoIngrediente] = useState('');
    const [sufixoIngrediente, setSufixoIngrediente] = useState('');
    const [quantidadeGramas, setQuantidadeGramas] = useState('');
    const [tempoPreparo, setTempoPreparo] = useState('');
    const [modoPreparo, setModoPreparo] = useState('');
    const [quantidadePorEmbalagem, setQuantidadePorEmbalagem] = useState('');
    const [categoria, setCategoria] = useState('');
    const [unidadeMedida, setUnidadeMedida] = useState('');
    const [observacoes, setObservacoes] = useState('');
    const [custoEstimado, setCustoEstimado] = useState(0);
    const [materiasPrimas, setMateriasPrimas] = useState([]);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [materiaPrima, setMateriaPrima] = useState({
        nome: '',
        tipo: '',
        categoria: '',
        marca: '',
        valorUnitario: '',
        quantidadePorEmbalagem: '',
        unidadeMateriaPrima: ''
    });
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const categoriasOptions = [
        { value: 'Bolos', label: 'Bolos' },
        { value: 'Tortas', label: 'Tortas' },
        { value: 'Salgados', label: 'Salgados' },
        { value: 'Doces', label: 'Doces' }
    ];

    useEffect(() => {
        axiosInstance.get("/materiaprima/")
            .then(result => {
                setMateriasPrimas(result.data);
            })
            .catch(error => {
                console.error('Erro ao buscar ingredientes:', error);
                setErrorMessage('Erro ao carregar ingredientes. Por favor, tente novamente mais tarde.');
            });
    }, []);

    useEffect(() => {
        if (novoIngrediente) {
            adicionarSufixo();
        }
    }, [novoIngrediente]);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!nomeReceita || !categoria) {
            setErrorMessage('Por favor, preencha o nome e a categoria da receita.');
            scrollToTopOnError();
            return;
        }

        salvarReceita();
    };

    const handleCategoriaChange = (e) => {
        setCategoria(e.target.value);
        setErrorMessage('')
    };

    const topRef = useRef(null);

    const submeterIngredienteNovo = (event) => {
        event.preventDefault();

        const materiaPrimaData = { ...materiaPrima, tipo: event.target.tipo.value };

        axiosInstance.post("/materiaprima/", materiaPrimaData)
            .then(result => {
                console.log(result);
                setShowSuccessMessage(true);
                setMateriaPrima({
                    nome: '',
                    tipo: '',
                    categoria: '',
                    marca: '',
                    valorUnitario: '',
                    quantidadePorEmbalagem: '',
                    unidadeMateriaPrima: '',
                });
                setIsPopupOpen(false);
                setMateriasPrimas(prevMateriasPrimas => [...prevMateriasPrimas, materiaPrimaData]);
                setNovoIngrediente(materiaPrimaData.nome);

                
            })
            .catch(error => {
                console.error('Erro ao cadastrar uma matéria prima:', error);
            });
    };

    const calcularCustoIngrediente = (ingrediente, quantidadePorEmbalagem) => {
        const selectedMateriaPrima = materiasPrimas.find(mP => mP.nome === ingrediente);
        if (selectedMateriaPrima) {
            const quantidadeEmGramas = unidadeParaGramas(selectedMateriaPrima.unidadeMateriaPrima, quantidadePorEmbalagem);
            const custoPorGrama = selectedMateriaPrima.valorUnitario / selectedMateriaPrima.quantidadePorEmbalagem;
            const custo = quantidadeEmGramas * custoPorGrama;
            return custo;
        }

        return 0;
    };


    const unidadeParaGramas = (unidade, quantidade) => {
        const quantidadeNumerica = parseFloat(quantidade);
        switch (unidade) {
            case 'kg':
                return quantidadeNumerica * 1000;
            case 'g':
                return quantidadeNumerica;
            case 'L':
                return quantidadeNumerica * 1000;
            case 'ml':
                return quantidadeNumerica;
            default:
                return quantidadeNumerica;
        }
    };

    const adicionarIngrediente = () => {
        if (novoIngrediente !== '' && quantidadeGramas !== '') {
            const ingredienteComQuantidade = `${novoIngrediente} - ${quantidadeGramas}${sufixoIngrediente}`;
            const novoCustoEstimado = calcularCustoIngrediente(novoIngrediente, quantidadeGramas);

            setIngredientes([...ingredientes, { nome: novoIngrediente, quantidadePorEmbalagem: quantidadeGramas, unidade: sufixoIngrediente, custoEstimado: novoCustoEstimado }]);

            setCustoEstimado(prevCusto => prevCusto + novoCustoEstimado);

            setNovoIngrediente('');
            setQuantidadeGramas('');

        }
    };




    const removerIngrediente = (index) => {
        const ingredienteRemovido = ingredientes[index];
        const { custoEstimado } = ingredienteRemovido;

        setCustoEstimado(prevCusto => prevCusto - custoEstimado);

        const novosIngredientes = ingredientes.filter((_, i) => i !== index);
        setIngredientes(novosIngredientes);
    };


    const handleValueChange = (values) => {
        const { value } = values;
        setMateriaPrima(prevState => ({
            ...prevState,
            valorUnitario: value
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMateriaPrima({ ...materiaPrima, [name]: value });
        setErrorMessage('');
    };

    const adicionarSufixo = () => {
        const selectedMateriaPrima = materiasPrimas.find(mP => mP.nome === novoIngrediente);
        if (selectedMateriaPrima) {
            switch (selectedMateriaPrima.unidadeMateriaPrima) {
                case 'kg':
                case 'g':
                    setSufixoIngrediente(' gramas');
                    break;
                case 'ml':
                case 'L':
                    setSufixoIngrediente(' ml');
                    break;
                case 'unid.':
                    setSufixoIngrediente(' unid.');
                    break;
                case 'm':
                case 'cm':
                    setSufixoIngrediente(' cm');
                    break;
                default:
                    setSufixoIngrediente('');
                    break;
            }
        } else {
            setSufixoIngrediente('');
        }
    };


    const ingredientesDisponiveis = materiasPrimas
        .filter(materiaPrima => materiaPrima.tipo === "Ingrediente")
        .sort((a, b) => a.nome.localeCompare(b.nome));

    const salvarReceita = () => {
        const receitaData = {
            nome: nomeReceita,
            materiasPrimas: ingredientes,
            categoria,
            tempoPreparo,
            modoPreparo,
            quantidadePorEmbalagem,
            unidadeMedida,
            observacoes,
            custoEstimado
        };

        axiosInstance.post("/receitas", receitaData)
            .then(response => {
                setShowSuccessMessage(true);
                setNomeReceita('');
                setIngredientes([]);
                setTempoPreparo('');
                setModoPreparo(''),
                setCategoria(''),
                setQuantidadePorEmbalagem('');
                setUnidadeMedida('');
                setObservacoes('');
                setCustoEstimado(0);

                navigate('/receita/listar', { state: { successMessage: 'Receita cadastrada com sucesso!' } });
            })
            .catch(error => {
                console.error('Erro ao salvar a receita:', error);
                setErrorMessage('Erro ao salvar a receita. Por favor, tente novamente mais tarde.');
            });
    };

    const scrollToTopOnError = () => {
        if (topRef.current) {
            topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <Menu />
            <div className="layout-container">
                <div ref={topRef} />
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="py-3 mb-4">Receitas</h4>
                        <div className="row">
                            <div className="col-xl">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">Dados da Receita</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="mb-3">
                                            <label className="form-label required" htmlFor="basic-default-fullname">Nome da Receita</label>
                                            <input type="text" required className="form-control" id="basic-default-fullname" name="nome" placeholder="ex.: Bolo de Chocolate " value={nomeReceita} onChange={(e) => setNomeReceita(e.target.value)} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label required" htmlFor="basic-default-fullname">Categoria da Receita</label>
                                            <select required
                                                className="form-control"
                                                id="basic-default-fullname"
                                                name="nome"
                                                value={categoria}
                                                onChange={handleCategoriaChange}
                                            >
                                                <option value="">Selecione uma categoria</option>
                                                {categoriasOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl">
                                <div className="card mb-4">
                                    <div className="card-header flex-direction-row align-items-center">
                                        <h5 className="mb-0">Adicionar Ingrediente</h5>
                                        <a href="#" style={{ fontSize: '12px' }} onClick={() => setIsPopupOpen(true)}>Não tem o ingrediente que precisa? Clique aqui para adicionar</a>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="basic-default-type">Ingredientes</label>
                                                <select
                                                    className="form-control"
                                                    id="basic-default-type"
                                                    name="tipo"
                                                    value={novoIngrediente}
                                                    onChange={(e) => {
                                                        setNovoIngrediente(e.target.value);
                                                        adicionarSufixo(); 
                                                    }}
                                                >
                                                    <option value="">Selecione um ingrediente</option>
                                                    {ingredientesDisponiveis.map((ingrediente) => (
                                                        <option key={ingrediente.nome} value={ingrediente.nome}>
                                                            {ingrediente.nome} - {ingrediente.marca}
                                                        </option>
                                                    ))}
                                                </select>

                                            </div>
                                            {novoIngrediente && (
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="basic-default-type">Quantidade a ser utilizada</label>
                                                    <NumericFormat
                                                        className="form-control"
                                                        id="basic-default-fullname"
                                                        name="quantidade"
                                                        value={quantidadeGramas}
                                                        onChange={(e) => setQuantidadeGramas(e.target.value)}
                                                        allowNegative={false}
                                                        decimalScale={2}
                                                        placeholder={`ex.: 100${sufixoIngrediente}`}
                                                        suffix={sufixoIngrediente}
                                                        required
                                                    />
                                                </div>
                                            )}


                                            <div className="mt-4">
                                                <button type="button" className="btn btn-primary" onClick={adicionarIngrediente}>Adicionar Ingrediente</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">Ingredientes Adicionados</h5>
                                    </div>
                                    <div className="card-body">
                                        <ul className="list-group">
                                            {ingredientes.map((ingrediente, index) => (
                                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                    {ingrediente.nome} - {ingrediente.quantidadePorEmbalagem} (R$ {ingrediente.custoEstimado ? ingrediente.custoEstimado.toFixed(2).replace('.', ',') : '0,00'})
                                                    <button type="button" className="btn btn-danger" onClick={() => removerIngrediente(index)}>Remover</button>
                                                </li>
                                            ))}

                                        </ul>
                                        <div className='mt-4'>Custo Estimado da Receita: R$ {custoEstimado !== undefined ? (isNaN(custoEstimado) ? '0,00' : custoEstimado.toFixed(2).replace('.', ',')) : '0,00'}</div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">Detalhes da Receita</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="basic-default-prep-time">Modo de Preparo</label>
                                            <textarea className="form-control" id="basic-default-modo-prep" name="modoPreparo" placeholder="ex.: Adicione 100ml de água..." value={modoPreparo} onChange={(e) => setModoPreparo(e.target.value)} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="basic-default-prep-time">Tempo de Preparo</label>
                                            <NumericFormat
                                                className="form-control"
                                                id="basic-default-prep-time"
                                                name="tempoPreparo"
                                                placeholder="ex.: 30 minutos"
                                                value={tempoPreparo}
                                                allowNegative={false}
                                                suffix=" minutos"
                                                onValueChange={(values) => setTempoPreparo(values.value)}
                                                format="####"
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="basic-default-quantity">Quantidade</label>
                                            <input type="text" className="form-control" id="basic-default-quantity" name="quantidade" placeholder="ex.: 10" value={quantidadePorEmbalagem} onChange={(e) => setQuantidadePorEmbalagem(e.target.value)} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="basic-default-unit">Unidade de Medida</label>
                                            
                                            <select className="form-control" id="basic-default-unity" name="unidadeMedida" value={unidadeMedida} onChange={(e) => setUnidadeMedida(e.target.value)}>
                                                    <option value="">Selecione a unidade</option>
                                                    <option value="L">Litros</option>
                                                    <option value="m">Metros</option>
                                                    <option value="ml">Mililitros</option>
                                                    <option value="g">Gramas</option>
                                                    <option value="kg">Kilogramas</option>
                                                    <option value="unid.">Unidade</option>
                                                    <option value="porcoes">Porções</option>
                                                    
                                                </select>
                                        
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="basic-default-observations">Observações</label>
                                            <textarea className="form-control" id="basic-default-observations" name="observacoes" placeholder="ex.: Receita fácil e rápida..." value={observacoes} onChange={(e) => setObservacoes(e.target.value)}></textarea>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button style={{fontSize: '15px'}} type="button" className="btn-green" onClick={handleSubmit}>Salvar Receita</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div >

            {isPopupOpen && (
                <div className="popup">
                    <div className="popup-inner">
                        <button className="btn-close" onClick={() => setIsPopupOpen(false)}></button>
                        <h4>Adicionar novo ingrediente</h4>
                        <form onSubmit={submeterIngredienteNovo}>
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="nome">Nome</label>
                                <input type="text" className="form-control" id="nome" name="nome" value={materiaPrima.nome} placeholder="ex.: Farinha de Trigo" onChange={(e) => setMateriaPrima({ ...materiaPrima, nome: e.target.value })} required />
                            </div>
                            <input type="hidden" id="tipo" name="tipo" value="Ingrediente" />
                            <div className="mb-3">
                                <label className="form-label" htmlFor="categoria">Categoria</label>
                                <select className="form-select" id="categoria" name="categoria" value={materiaPrima.categoria} onChange={(e) => setMateriaPrima({ ...materiaPrima, categoria: e.target.value })}>
                                    <option value="">Selecione...</option>
                                    <option value="Perecível">Perecível</option>
                                    <option value="Não Perecível">Não Perecível</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="marca">Marca</label>
                                <input type="text" className="form-control" id="marca" name="marca" placeholder="Marca da matéria-prima" value={materiaPrima.marca} onChange={(e) => setMateriaPrima({ ...materiaPrima, marca: e.target.value })} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="valorUnitario">Valor Unitário</label>
                                <NumericFormat
                                    className="form-control"
                                    id="valorUnitario"
                                    name="valorUnitario"
                                    value={materiaPrima.valorUnitario}
                                    onValueChange={handleValueChange}
                                    placeholder="R$ 0,00"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    prefix="R$ "
                                    fixedDecimalScale
                                    decimalScale={2}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="quantidadePorEmbalagem">Quantidade por Embalagem</label>
                                <input type="text" className="form-control" id="quantidadePorEmbalagem" placeholder="Ex.: 500" name="quantidadePorEmbalagem" value={materiaPrima.quantidadePorEmbalagem} onChange={(e) => setMateriaPrima({ ...materiaPrima, quantidadePorEmbalagem: e.target.value })} required />
                            </div>
                            <div className="mb-3">
                                <label className="form-label required" htmlFor="unidadeMateriaPrima">Unidade da Matéria Prima</label>
                                <select className="form-control" id="basic-default-unity" name="unidadeMateriaPrima" value={materiaPrima.unidadeMateriaPrima} onChange={handleChange}>
                                    <option value="">Selecione a unidade</option>
                                    <option value="L">Litros</option>
                                    <option value="m">Metros</option>
                                    <option value="ml">Mililitros</option>
                                    <option value="g">Gramas</option>
                                    <option value="kg">Kilogramas</option>
                                    <option value="unid.">Unidade</option>
                                    <option value="cm">Centimetros</option>
                                </select>
                            </div>
                            <button type="submit" className="btn btn-primary">Adicionar</button>
                            <button type="button" className="btn btn-secondary" onClick={() => setIsPopupOpen(false)}>Cancelar</button>
                        </form>
                    </div>
                </div>
            )}
        </div >
    );
}

export default CreateReceita;
