import React, { useEffect, useState } from 'react';
import axiosInstance from '../services/axiosInstance';
import Menu from '../menu/Menu.js';
import { NumericFormat } from 'react-number-format';
import '../../index.css';
import { useNavigate } from 'react-router-dom';
import useAuth from '../services/useAuth';

function CreateMateriaPrima() {

    useAuth();

    const [materiaPrima, setMateriaPrima] = useState({
        nome: '',
        tipo: '',
        categoria: '',
        marca: '',
        valorUnitario: '',
        quantidadePorEmbalagem: '',
        unidadeMateriaPrima: ''
    });
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get("/materiaprima/")
            .then(result => {
                setMateriaPrima(result.data);
            })
            .catch(error => {
                console.error('Erro ao buscar Matéria Prima:', error);
            });

        const scripts = [
            '../assets/vendor/libs/jquery/jquery.js',
            '../assets/vendor/libs/popper/popper.js',
            '../assets/vendor/js/bootstrap.js',
            '../assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.js',
            '../assets/js/main.js'
        ];

        const loadScripts = () => {
            scripts.forEach(src => {
                const script = document.createElement('script');
                script.src = src;
                script.async = true;
                document.head.appendChild(script);
            });
        };

        if (window.Menu) {
            loadScripts();
        } else {
            window.addEventListener('load', loadScripts);
        }

        return () => {
            scripts.forEach(src => {
                const script = document.querySelector(`script[src="${src}"]`);
                if (script) {
                    document.head.removeChild(script);
                }
            });
        };
    }, []);

    function handleChange(event) {
        const { name, value } = event.target;
        setMateriaPrima(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    function handleSubmit(event) {
        event.preventDefault();

        const materiaPrimaData = { ...materiaPrima };
        axiosInstance.post("/materiaprima/", materiaPrimaData)
            .then(result => {
                console.log(result);
                setShowSuccessMessage(true);
                setMateriaPrima({
                    nome: '',
                    tipo: '',
                    categoria: '', 
                    marca: '',
                    valorUnitario: '',
                    quantidadePorEmbalagem: '',
                    unidadeMateriaPrima: ''
                });
                navigate('/estoque', { state: { successMessage: 'Matéria Prima cadastrada com sucesso!' } });
            })
            .catch(error => {
                console.error('Erro ao cadastrar uma matéria prima:', error);
            });
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <Menu />
            <div className="layout-container">
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        {showSuccessMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                Cadastro realizado com sucesso!
                            </div>
                        )}
                        <div className="row">
                            <div className="col-xl">
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        <h5 className="mb-0">Adicionar Matéria Prima</h5>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="basic-default-fullname">Nome</label>
                                                <input type="text" className="form-control" id="basic-default-fullname" name="nome" value={materiaPrima.nome} onChange={handleChange} placeholder="ex.: Farinha de Trigo" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="basic-default-type">Tipo</label>
                                                <select className="form-control" id="basic-default-type" name="tipo" value={materiaPrima.tipo} onChange={handleChange}>
                                                    <option value="">Selecione o tipo</option>
                                                    <option value="Ingrediente">Ingrediente</option>
                                                    <option value="Embalagem">Embalagem</option>
                                                    <option value="Decoracao">Decoração</option>
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="basic-default-category">Categoria</label>
                                                <select className="form-control" id="basic-default-category" name="categoria" value={materiaPrima.categoria} onChange={handleChange}>
                                                    <option value="">Selecione a categoria</option>
                                                    <option value="perecivel">Perecível</option>
                                                    <option value="nao-perecivel">Não Perecível</option>
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="basic-default-company">Marca</label>
                                                <input type="text" className="form-control" id="basic-default-company" name="marca" value={materiaPrima.marca} onChange={handleChange} placeholder="Marca da matéria-prima" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="basic-default-company">Valor Unitário</label>
                                                <NumericFormat
                                                    className="form-control"
                                                    id="basic-default-company"
                                                    name="valorUnitario"
                                                    value={materiaPrima.valorUnitario}
                                                    onValueChange={(values) => {
                                                        const { value } = values;
                                                        setMateriaPrima(prevState => ({
                                                            ...prevState,
                                                            valorUnitario: value
                                                        }));
                                                    }}
                                                    placeholder="R$ 0,00"
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    prefix="R$ "
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="basic-default-quantity-packaging">Quantidade por Embalagem</label>
                                                <input type="text" className="form-control" id="basic-default-quantity-packaging" name="quantidadePorEmbalagem" value={materiaPrima.quantidadePorEmbalagem} onChange={handleChange} placeholder="Ex.: 500" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="basic-default-unit-raw-material">Unidade Matéria Prima</label>
                                                <select className="form-control" id="basic-default-unity" name="unidadeMateriaPrima" value={materiaPrima.unidadeMateriaPrima} onChange={handleChange}>
                                                    <option value="">Selecione a unidade</option>
                                                    <option value="L">Litros</option>
                                                    <option value="m">Metros</option>
                                                    <option value="ml">Mililitros</option>
                                                    <option value="g">Gramas</option>
                                                    <option value="kg">Kilogramas</option>
                                                    <option value="unid.">Unidade</option>
                                                    <option value="cm">Centimetros</option>
                                                </select>
                                            </div>
                                            <input type="hidden" onChange={handleChange} name="estoque" value={1}></input>
                                            <button style={{fontSize: '15px'}} type="submit" className="btn-green">Enviar</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="content-footer footer bg-footer-theme">
                        <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                            <div className="mb-2 mb-md-0">
                                ©
                                <script>
                                    document.write(new Date().getFullYear());
                                </script>
                                by
                                <a href="https://themeselection.com" target="_blank" className="footer-link fw-medium">Doceria Smart</a>
                            </div>
                        </div>
                    </footer>
                    <div className="content-backdrop fade"></div>
                </div>
            </div>
        </div>
    );
}

export default CreateMateriaPrima;
