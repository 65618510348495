import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../services/axiosInstance';
import Menu from '../menu/Menu.js';
import { NumericFormat } from 'react-number-format';
import '../../index.css';
import useAuth from '../services/useAuth';

function EditMateriaPrima() {

    useAuth();

    const { id } = useParams();
    const [materiaPrima, setMateriaPrima] = useState({
        nome: '',
        tipo: '',
        categoria: '',
        marca: '',
        valorUnitario: '',
        quantidadePorEmbalagem: '',
        unidadeMateriaPrima: ''
    });
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axiosInstance.get(`/materiaprima/${id}`)
            .then(result => {
                setMateriaPrima(result.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Erro ao buscar matéria prima:', error);
            });
    }, [id]);

    function handleChange(event) {
        const { name, value } = event.target;
        setMateriaPrima(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    function handleSubmit(event) {
        event.preventDefault();
        axiosInstance.put(`/materiaprima/editar/${id}`, materiaPrima)
            .then(result => {
                console.log(result);
                setShowSuccessMessage(true);
                navigate('/estoque', { state: { successMessage: 'Matéria Prima atualizada com sucesso!' } });
            })
            .catch(error => {
                console.error('Erro ao atualizar matéria prima:', error);
            });
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <Menu />
            <div className="layout-container">
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="py-3 mb-4">Editar Matéria Prima</h4>
                        {isLoading ? (
                            <div>Carregando...</div>
                        ) : (
                            <div className="row">
                                <div className="col-xl">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <form onSubmit={handleSubmit}>
                                                <div>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="basic-default-fullname">Nome</label>
                                                        <input type="text" className="form-control" id="basic-default-fullname" name="nome" value={materiaPrima.nome} onChange={handleChange} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="basic-default-type">Tipo</label>
                                                        <select className="form-select mb-2" id="basic-default-type" name="tipo" value={materiaPrima.tipo} onChange={handleChange}>
                                                            <option value="indefinido">Selecione o tipo</option>
                                                            <option value="Ingrediente">Ingrediente</option>
                                                            <option value="Embalagem">Embalagem</option>
                                                            <option value="Decoracao">Decoração</option>
                                                        </select>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="basic-default-category">Categoria</label>
                                                        <select className="form-select mb-2" id="basic-default-category" name="categoria" value={materiaPrima.categoria} onChange={handleChange}>
                                                            <option value="">Selecione a categoria</option>
                                                            <option value="perecivel">Perecível</option>
                                                            <option value="nao-perecivel">Não Perecível</option>
                                                        </select>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="basic-default-company">Marca</label>
                                                        <input type="text" className="form-control" id="basic-default-company" name="marca" value={materiaPrima.marca} onChange={handleChange} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="basic-default-company">Valor Unitário</label>
                                                        <NumericFormat
                                                            className="form-control"
                                                            id="basic-default-company"
                                                            name="valorUnitario"
                                                            value={materiaPrima.valorUnitario}
                                                            onValueChange={(values) => {
                                                                const { value } = values;
                                                                setMateriaPrima(prevState => ({
                                                                    ...prevState,
                                                                    valorUnitario: value
                                                                }));
                                                            }}
                                                            placeholder="R$ 0,00"
                                                            thousandSeparator="."
                                                            decimalSeparator=","
                                                            prefix="R$ "
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="basic-default-quantity-packaging">Quantidade por Embalagem</label>
                                                        <input type="text" className="form-control" id="basic-default-quantity-packaging" name="quantidadePorEmbalagem" value={materiaPrima.quantidadePorEmbalagem} onChange={handleChange} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="basic-default-unit-raw-material">Unidade Matéria Prima</label>
                                                        <select className="form-select mb-2" id="basic-default-unity" name="unidadeMateriaPrima" value={materiaPrima.unidadeMateriaPrima} onChange={handleChange}>
                                                            <option value="">Selecione a unidade</option>
                                                            <option value="L">Litros</option>
                                                            <option value="m">Metros</option>
                                                            <option value="ml">Mililitros</option>
                                                            <option value="g">Gramas</option>
                                                            <option value="kg">Kilogramas</option>
                                                            <option value="unid.">Unidade</option>
                                                            <option value="cm">Centímetros</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-primary">Salvar</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {showSuccessMessage && (
                            <div className="alert alert-success mt-3" role="alert">
                                Matéria Prima atualizada com sucesso!
                            </div>
                        )}
                    </div>
                    <footer className="content-footer footer bg-footer-theme">
                        <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
                            <div className="mb-2 mb-md-0">
                                ©{new Date().getFullYear()} by <a href="https://themeselection.com" target="_blank" className="footer-link fw-medium">Doceria Smart</a>
                            </div>
                        </div>
                    </footer>
                    <div className="content-backdrop fade"></div>
                </div>
            </div>
        </div>
    );
}

export default EditMateriaPrima;
